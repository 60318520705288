<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12>
            <v-app-bar color="white" >
              <v-layout row justify-space-between align-center px-3>
                
                <!-- Logo Section (Left) -->
                <v-flex xs4 sm2 md2 lg2>
                  <v-img
                    max-width="130px" 
                    max-height="100px"
                    src="../assets/Images/wti_Logo2.png"
                  ></v-img>
                </v-flex>
            
                <!-- Text Section (Right) -->
                <v-flex xs8 sm10 md10 lg10 class="text-right">
                  <v-layout row justify-end align-center>
                    <!-- Lock Icon -->
                    <v-icon left small>mdi-lock</v-icon>
                    <span style="font-family:poppinsregular;font-size:14px">This site is secure</span>
                   </v-layout>
                </v-flex>
            
              </v-layout>
            </v-app-bar>
            
            <v-img width="auto" class="hidden-xs-only"   :max-height="imageHeight" src="../assets/Images/bearcubs1.jpg">
              <v-flex pa-0 pa-lg-16 pa-sm-16 pa-md-16 >
                
                  <v-layout row justify-start>
                    <v-flex xs12 sm10 md6 lg4>
                      <v-card outlined style="border: 5px solid #005f32" elevation="3" v-if="firstCard" class="animate2 pt-8" id="div2">
                       
                       
                        <v-flex xs12  px-5 text-center style="color: black">
                          <span class="popregular " style="font-size:28px">Your gift will support orphaned bear cubs at CBRC</span>
                         </v-flex>
                         <v-flex xs12 pt-3 px-5 text-justify style="color: black; font-size:14px">
                          <span class="popregular"
                          >We urgently require funds for CBRC - our wildlife rescue and rehabilitation center - in Arunachal Pradesh. CBRC gives a second chance at life to orphaned bear cubs and other species by rehabilitating and returning them to their true homes in the wild.
                          Your donation will help provide care for the bear cubs and other animals currently admitted at CBRC, as well as support the maintenance of the facility.</span
                        >
                         </v-flex>
                       
                       
                       
                       
                       
                        <v-flex xs12 pt-5>
                          <span
                            style="
                              color: #005f32;
                              font-family: montserratbold;
                              font-size: 20px; 
                            "
                            >Donate Now</span
                          >
                        </v-flex>

                        <v-layout row justify-center pt-4 >
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn"
                              :class="
                                donation === BTN1 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN1"
                            >
                              {{ sign }}{{ BTN1 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn"
                              :class="
                                donation === BTN2 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN2"
                            >
                              {{ sign }}{{ BTN2 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn"
                              :class="
                                donation === BTN3 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN3"
                            >
                              {{ sign }}{{ BTN3 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs12 pt-5>
                            <v-btn
                              outlined
                              class="amount-btn"    :class="
                              donation === null ? 'selected-amount' : ''
                            "
                              @click="toTextField()" style="font-size: 16px; text-transform: none"
                            >
                            Any Other Amount
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <!-- Other Amount Input -->
                        <!-- <v-text-field
                v-model="donation"
                label="£ - Other Amount"
                outlined
                hide-details
              ></v-text-field> -->

                        <v-flex xs12 pt-2 pa-4>
                          <v-text-field
                            v-model="donation"
                            class="center pt-5"
                            dense
                            label="₹ - Other Amount"
                            outlined
                            hide-details
                          >
                            <template v-slot:prepend-inner>
                              <v-layout justify-center>
                                <v-flex
                                  xs12
                                  class="align-self-center"
                                  style="border-right: 1px dotted grey"
                                >
                                  <span
                                    style="
                                      padding-right: 2px;
                                      font-family: poppinsregular;
                                      font-size: 12px;
                                      color: #000;
                                    "
                                    >{{ Cname + sign }} </span
                                  >
                                </v-flex>
                              </v-layout>
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex px-4 pt-0>
                          <!-- Donate Button -->
                          <v-btn
                            block
                            large
                            color="#005f32"
                            class="white--text donate-btn"
                            @click="
                              (secondCard = true), (firstCard = false), login()
                            "
                          >
                            Continue <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-flex>

                        <!-- Payment Logos -->
                        <v-layout row justify-center class="payment-logos mt-3">
                          <v-img
                            src="direct_debit_logo.png"
                            class="mx-2"
                            contain
                            height="30"
                          ></v-img>
                          <v-img
                            src="paypal_logo.png"
                            class="mx-2"
                            contain
                            height="30"
                          ></v-img>
                        </v-layout>
                      </v-card>

                      <v-card elevation="3" v-if="secondCard" outlined style="border: 5px solid #005f32" >
                        <v-form ref="file">
                          <v-layout wrap justify-center pa-3 class="animate" id="div1">
                            <v-flex xs12>
                              <v-layout wrap style="background-color: #f2f2f2">
                                <v-flex
                                  xs1
                                  align-self-center
                                  style="background-color: #f2f2f2"
                                >
                                  <v-icon @click="firstCard=true,secondCard=false">mdi-chevron-left</v-icon>
                                </v-flex>
                                <v-flex
                                  xs10
                                  py-2
                                  style="
                                    background-color: #f2f2f2;
                                    margin-bottom: 1px solid grey;
                                  "
                                >
                                  <span class="popregular">Add Your Information.</span>
                                </v-flex>
                                <v-flex xs1 py-3>
                                  <v-spacer></v-spacer>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <!-- <v-flex xs12 py-6 text-center style="color: grey">
                              <span class="popregular " style="font-size:20px">Who's giving today?</span>
                              <br />
              
                              <i>
                                <span class="popregular"
                                  >We’ll never share this information with anyone</span
                                ></i
                              >
                            </v-flex> -->
                           
                            <v-flex xs12 px-5 pt-4>
                              <v-text-field
                                class="center2"
                                type="number"
                                ref="amount" dense
                                color="#828282"
                                placeholder="Amount"
                                label="Amount"
                               
                                outlined 
                                rounded
                                :rules="[rules.required]"
                                v-model="donation"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                ref="firstname" dense
                                color="#828282"
                              
                                label="First Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="firstname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                ref="lastname"
                                color="#828282"
                             
                                label="Last Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="lastname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                ref="email"
                                color="#828282"
                             
                                outlined
                                rounded
                                class="center2"
                                label="Email"
                                :rules="[rules.email]"
                                v-model="email"
                              ></v-text-field>
                            </v-flex>
                              <template v-if="taxExemption == true">
                             <v-flex xs12 px-5>
              
                             
                              <v-text-field
                                color="#828282" dense
                               
                                outlined
                                rounded
                                class="center2"
                                label="Address"
                                :rules="[rules.required]"
                                v-model="Newaddress"
                              ></v-text-field>
                            </v-flex>
                             <v-flex xs12 px-5>
                              <v-text-field
                                color="#828282"
                              
                                outlined dense
                                rounded
                                class="center2"
                                label="City"
                                :rules="[rules.required]"
                                v-model="Newcity"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                color="#828282"
                               
                                outlined
                                rounded
                                class="center2"
                                label="Pincode"
                                :rules="[rules.required]"
                                v-model="Newpincode"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                color="#828282" dense
                              
                                outlined
                                rounded
                                class="center2"
                                label="Pan Number"
                                :rules="[rules.required]"
                                v-model="Newpannumber"
                              ></v-text-field>
                            </v-flex>
                            </template>
              
              
              
              
              
              
                         <v-flex xs12 px-5 pt-2>
                              <v-checkbox
                                v-model="taxExemption"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="I wish to claim tax exemption against this donation"
                              ></v-checkbox>
                            </v-flex>
              
                            <v-flex xs12 px-5 >
                              <v-checkbox
                                v-model="isanonymous"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="Make my donation anonymous "
                              ></v-checkbox>
                            </v-flex>
                             
                            <v-flex xs12 px-5>
                              <v-checkbox
                                v-model="subscribe"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="
                              Subscribe to our newsletter for inspiring stories from our
                              projects"
                              ></v-checkbox>
                            </v-flex>
              
                            <v-flex xs12 pt-4>
                              <v-btn
                                @click="validate()"
                             
                                color="#005f32"
                                dark   block large
                                style="
                                  font-size: 20px;
                                  text-transform: none;
                                  font-family: poppinsregular;
                                "
                              
                                >Donate Now
                              </v-btn>
                            </v-flex>
              
                            <v-flex
                              class="popregular"
                              xs12
                              py-5
                              text-center
                              style="background-color: #f2f2f2"
                            >
                              <span><v-icon small>mdi-lock</v-icon> </span>
                              <span>Secure Donation</span>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card>
                    </v-flex>

                    
                  </v-layout>
              
              </v-flex>
            </v-img>


        
              <v-flex pa-0 pa-lg-16 pa-sm-16 pa-md-16 class="hidden-sm-and-up" >
                
                  <v-layout row justify-start>
                    <v-flex xs12 sm10 md6 lg4>
                      <v-card outlined style="border: 5px solid #005f32" elevation="3" v-if="firstCard" class="animate2 pt-8" id="div2">
                        <v-flex xs12  px-5 text-center style="color: black">
                          <span class="popregular " style="font-size:28px">Your gift will support orphaned bear cubs at CBRC</span>
                         </v-flex>
                         <v-flex xs12 pt-3 px-5 text-justify style="color: black; font-size:14px">
                          <span class="popregular"
                          >We urgently require funds for CBRC - our wildlife rescue and rehabilitation center - in Arunachal Pradesh. CBRC gives a second chance at life to orphaned bear cubs and other species by rehabilitating and returning them to their true homes in the wild.
                          Your donation will help provide care for the bear cubs and other animals currently admitted at CBRC, as well as support the maintenance of the facility.</span
                        >
                         </v-flex>
                        <v-flex xs12 pt-5>
                          <span
                            style="
                              color: #005f32;
                              font-family: montserratbold;
                              font-size: 20px; 
                            "
                            >Donate Now</span
                          >
                        </v-flex>

                        <v-layout row justify-center pt-4 >
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn1"
                              :class="
                                donation === BTN1 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN1"
                            >
                              {{ sign }}{{ BTN1 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn1"
                              :class="
                                donation === BTN2 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN2"
                            >
                              {{ sign }}{{ BTN2 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs4>
                            <v-btn
                              outlined
                              class="amount-btn1"
                              :class="
                                donation === BTN3 ? 'selected-amount' : ''
                              "
                              @click="donation = BTN3"
                            >
                              {{ sign }}{{ BTN3 }}
                            </v-btn>
                          </v-flex>
                          <v-flex xs12 pt-5>
                            <v-btn
                              outlined
                              class="amount-btn"    :class="
                              donation === null ? 'selected-amount' : ''
                            "
                              @click="toTextField()" style="font-size: 16px; text-transform: none"
                            >
                            Any Other Amount
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <!-- Other Amount Input -->
                        <!-- <v-text-field
                v-model="donation"
                label="£ - Other Amount"
                outlined
                hide-details
              ></v-text-field> -->

                        <v-flex xs12 pt-2 pa-4>
                          <v-text-field
                            v-model="donation"
                            class="center pt-5"
                            dense
                            label="₹ - Other Amount"
                            outlined
                            hide-details
                          >
                            <template v-slot:prepend-inner>
                              <v-layout justify-center>
                                <v-flex
                                  xs12
                                  class="align-self-center"
                                  style="border-right: 1px dotted grey"
                                >
                                  <span
                                    style="
                                      padding-right: 2px;
                                      font-family: poppinsregular;
                                      font-size: 12px;
                                      color: #000;
                                    "
                                    >{{ Cname + sign }}</span
                                  >
                                </v-flex>
                              </v-layout>
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex px-4 pt-0>
                          <!-- Donate Button -->
                          <v-btn
                            block
                            large
                            color="#005f32"
                            class="white--text donate-btn"
                            @click="
                              (secondCard = true), (firstCard = false), login()
                            "
                          >
                            Continue <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-flex>

                        <!-- Payment Logos -->
                        <v-layout row justify-center class="payment-logos mt-3">
                          <v-img
                            src="direct_debit_logo.png"
                            class="mx-2"
                            contain
                            height="30"
                          ></v-img>
                          <v-img
                            src="paypal_logo.png"
                            class="mx-2"
                            contain
                            height="30"
                          ></v-img>
                        </v-layout>
                      </v-card>

                      <v-card elevation="3" v-if="secondCard" outlined style="border: 5px solid #005f32" >
                        <v-form ref="file">
                          <v-layout wrap justify-center pa-3 class="animate" id="div1">
                            <v-flex xs12>
                              <v-layout wrap style="background-color: #f2f2f2">
                                <v-flex
                                  xs1
                                  align-self-center
                                  style="background-color: #f2f2f2"
                                >
                                  <v-icon @click="firstCard=true,secondCard=false">mdi-chevron-left</v-icon>
                                </v-flex>
                                <v-flex
                                  xs10
                                  py-2
                                  style="
                                    background-color: #f2f2f2;
                                    margin-bottom: 1px solid grey;
                                  "
                                >
                                  <span class="popregular">Add Your Information.</span>
                                </v-flex>
                                <v-flex xs1 py-3>
                                  <v-spacer></v-spacer>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <!-- <v-flex xs12 py-6 text-center style="color: grey">
                              <span class="popregular " style="font-size:20px">Who's giving today?</span>
                              <br />
              
                              <i>
                                <span class="popregular"
                                  >We’ll never share this information with anyone</span
                                ></i
                              >
                            </v-flex> -->
                           
                            <v-flex xs12 px-5 pt-4>
                              <v-text-field
                                class="center2"
                                type="number"
                                ref="amount" dense
                                color="#828282"
                                placeholder="Amount"
                                label="Amount"
                               
                                outlined 
                                rounded
                                :rules="[rules.required]"
                                v-model="donation"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                ref="firstname" dense
                                color="#828282"
                              
                                label="First Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="firstname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                ref="lastname"
                                color="#828282"
                             
                                label="Last Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="lastname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                ref="email"
                                color="#828282"
                             
                                outlined
                                rounded
                                class="center2"
                                label="Email"
                                :rules="[rules.email]"
                                v-model="email"
                              ></v-text-field>
                            </v-flex>
                              <template v-if="taxExemption == true">
                             <v-flex xs12 px-5>
              
                             
                              <v-text-field
                                color="#828282" dense
                               
                                outlined
                                rounded
                                class="center2"
                                label="Address"
                                :rules="[rules.required]"
                                v-model="Newaddress"
                              ></v-text-field>
                            </v-flex>
                             <v-flex xs12 px-5>
                              <v-text-field
                                color="#828282"
                              
                                outlined dense
                                rounded
                                class="center2"
                                label="City"
                                :rules="[rules.required]"
                                v-model="Newcity"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field dense
                                color="#828282"
                               
                                outlined
                                rounded
                                class="center2"
                                label="Pincode"
                                :rules="[rules.required]"
                                v-model="Newpincode"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                color="#828282" dense
                              
                                outlined
                                rounded
                                class="center2"
                                label="Pan Number"
                                :rules="[rules.required]"
                                v-model="Newpannumber"
                              ></v-text-field>
                            </v-flex>
                            </template>
              
              
              
              
              
              
                         <v-flex xs12 px-5 pt-2>
                              <v-checkbox
                                v-model="taxExemption"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="I wish to claim tax exemption against this donation"
                              ></v-checkbox>
                            </v-flex>
              
                            <v-flex xs12 px-5 >
                              <v-checkbox
                                v-model="isanonymous"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="Make my donation anonymous "
                              ></v-checkbox>
                            </v-flex>
                             
                            <v-flex xs12 px-5>
                              <v-checkbox
                                v-model="subscribe"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="
                              Subscribe to our newsletter for inspiring stories from our
                              projects"
                              ></v-checkbox>
                            </v-flex>
              
                            <v-flex xs12 pt-4>
                              <v-btn
                                @click="validate()"
                             
                                color="#005f32"
                                dark   block large
                                style="
                                  font-size: 20px;
                                  text-transform: none;
                                  font-family: poppinsregular;
                                "
                              
                                >Donate Now
                              </v-btn>
                            </v-flex>
              
                            <v-flex
                              class="popregular"
                              xs12
                              py-5
                              text-center
                              style="background-color: #f2f2f2"
                            >
                              <span><v-icon small>mdi-lock</v-icon> </span>
                              <span>Secure Donation</span>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card>
                    </v-flex>

                    
                  </v-layout>
              
              </v-flex>
              <v-img width="auto" class="hidden-sm-and-up"  max-height="300" src="../assets/Images/bearcubs1.jpg">
            </v-img>

          </v-flex>

          <!-- <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0>
              <span class="popregular medium" style="color: black"
                >By supporting Wildlife Trust of India, you help protect
                endangered animals, provide emergency relief to animals in
                distress, and help prevent the destruction of critical wild
                habitats.</span
              >
            </v-flex> -->
        </v-layout>
      </v-flex>
  
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      Newaddress: "",
      Newcity: "",
      Newpincode: "",
      Newpannumber: "",

      //inherited varibles from donation Card
      BTN1: 5000,
      BTN2: 10000,
      BTN3: 20000,
      sign: "₹",
      Cname: "INR",

      country: "India",
      //amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],

      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: 5000,
      firstCard: true,
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,

      // new items
      tab: 0, // Default to the first tab
      amount: 5000, // Default selected amount for the first button
      customAmount: "",
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 100;
        this.BTN2 = 200;
        this.BTN3 = 500;
        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 5000;
        this.BTN2 = 10000;
        this.BTN3 = 20000;
        this.sign = "₹";
        this.Cname = "INR";

        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  computed: {
    // Dynamically adjust the image height based on the current card
    imageHeight() {
      return this.firstCard ? '800px' : '950px'; // Example heights for first and second card
    },
  },

  methods: {
    setTab(index) {
      this.tab = index; // Set the selected tab
    },
    setAmount(value) {
      this.donation = value; // Set the selected amount
      this.customAmount = value; // Set the amount in the text field
    },
    login() {
      this.$gtag.event("login", { method: "Google" });
    },

    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        don["donationtype"] = 'Support CBRC';
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        // don["pincode"] = this.Newpincode;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;

        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
  <style  scoped>
.LBL >>> label {
  font-family: poppinsregular !important;
  font-size: 12px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}
.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 22px;
}
.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 15px;
}
.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;
}
.boldR >>> label {
  font-family: poppinsbold;
}
.animate {
  position: relative;
  animation: mymove 0.25s;
}
.animate2 {
 
  position: relative;
  animation: mymove2 0.25s;
}
#div2 {
  animation-timing-function: linear;
}
@keyframes mymove2 {
  from {
    left: -200px;
  }
  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
}
#div1 {
  animation-timing-function: linear;
}
.v-divider {
  display: none !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear; /* Safari */
  animation: spin 2s linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-checkbox >>> label {
  font-family: poppinsregular;
}

/* Donation Tabs */
/* Custom Tabs Styling */


.custom-tab {
  font-weight: bold;
  font-size: 18px;
  color: #444; /* Default color for tabs */
  padding: 20px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 3px solid transparent; /* Transparent border to avoid jumping */
}

/* Default tab styling */
.default-tab {
  color: #444; /* Ensure color is the same for unselected */
}

.amount-btn {
  font-size: 18px;
  color: #000000;
  border: 2px solid #e5e5e5;
  font-family: poppinsregular;
  background-color: white;
}
.amount-btn1 {
  font-size: 16px;
  color: #000000;
  border: 2px solid #e5e5e5;
  font-family: poppinsregular;
  background-color: white;
}

.selected-amount {
  background-color: #005f32;
  color: #005f32;
  border-color: #005f32;
}

.selected-amount .v-btn__content {
  color: white !important; /* Ensure text color is white */
}

/* Donate Button */
.donate-btn {
  background-color: #e63946;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
}

/* Payment Logos */
.payment-logos img {
  margin: 10px;
}

</style>